import React from 'react'

import Page from '~/templates/Page'

const Widerrufsbelehrung = () => (
  <Page title="Widerrufsbelehrung">
    <b>Widerrufsrecht für Verbraucher</b>
    <br />
    <br />
    <span>
      Verbrauchern steht ein Widerrufsrecht nach folgender Maßgabe zu, wobei
      Verbraucher jede natürliche Person ist, die ein Rechtsgeschäft zu Zwecken
      abschließt, die überwiegend weder ihrer gewerblichen noch ihrer
      selbständigen beruflichen Tätigkeit zugerechnet werden können:{' '}
    </span>
    <br />
    <br />
    <b>Widerrufsrecht</b>
    <br />
    <br />
    <span>
      Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen
      Vertrag zu widerrufen.{' '}
    </span>
    <br />
    <br />
    <span>
      Die Widerrufsfrist beträgt vierzehn Tage, ab dem Tag, an dem Sie oder ein
      von Ihnen benannter Dritter, der nicht der Beförderer ist, die Waren in
      Besitz genommen haben bzw. hat.{' '}
    </span>
    <br />
    <br />
    <span>Um Ihr Widerrufsrecht auszuüben, müssen Sie uns</span>
    <br />
    <div>
      1050 GbR
      <br />
      Steilstraße 1a
      <br />
      90513 Zirndorf
      <br />
      <br />
      Vertreten durch die Gesellschafter: Jens Herga, Alexander Hörl, Kai Zippe,
      Anvar Gallyaniev, Rabije Dzaferi, Ole Preisig
    </div>
    <span>
      mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter
      Brief, Telefax oder E-Mail) über Ihren Entschluss, diesen Vertrag zu
      widerrufen, informieren. Sie können dafür das beigefügte
      Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.{' '}
    </span>
    <br />
    <br />
    <b>Widerrufsfolgen</b>
    <span> </span>
    <br />
    <br />
    <span>
      Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die
      wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit
      Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine
      andere Art der Lieferung als die von uns angebotene, günstigste
      Standardlieferung gewählt haben), unverzüglich und spätestens binnen
      vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren
      Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung
      verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen
      Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich
      etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser
      Rückzahlung Entgelte berechnet.{' '}
    </span>
    <br />
    <br />
    <span>
      Wir können die Rückzahlung verweigern, bis wir die Waren wieder
      zurückerhalten haben oder bis Sie den Nachweis erbracht haben, dass Sie
      die Waren zurückgesandt haben, je nachdem, welches der frühere Zeitpunkt
      ist.{' '}
    </span>
    <br />
    <br />
    <span>
      Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen
      vierzehn Tagen ab dem Tag, an dem Sie uns über den Widerruf dieses
      Vertrages unterrichten, an uns zurückzusenden oder zu übergeben. Die Frist
      ist gewahrt, wenn Sie die Waren vor Ablauf der Frist von vierzehn Tagen
      absenden.{' '}
    </span>
    <br />
    <br />
    <span>Sie tragen die unmittelbaren Kosten der Rücksendung der Waren.</span>
    <br />
    <br />
    <b>Ende der Widerrufsbelehrung</b>
    <span> </span>
    <br />
    <br />
    <span>
      ****************************************************************************************************{' '}
    </span>
    <br />
    <p>§7 Widerrufsformular</p>
    <p>Muster-Widerrufsformular</p>
    <span>
      (Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses
      Formular aus und senden Sie es zurück.)
    </span>
    <br />
    <div>
      An :<br />
      1050 GbR
      <br />
      Steilstraße 1a
      <br />
      D-90753 Zirndorf
      <br />
      E-Mail info@nureinberg.de
      <br />
      <br />
      Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*) abgeschlossenen
      Vertrag über den Kauf der folgenden Waren (*)/die Erbringung der folgenden
      Dienstleistung (*)
      <br />
      <br />
      _____________________________________________________
      <br />
      <br />
      Bestellt am (*)/erhalten am (*)
      <br />
      <br />
      __________________
      <br />
      <br />
      Name des/der Verbraucher(s)
      <br />
      <br />
      _____________________________________________________
      <br />
      <br />
      Anschrift des/der Verbraucher(s)
      <br />
      <br />
      <br />
      _____________________________________________________
      <br />
      <br />
      Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf Papier)
      <br />
      <br />
      __________________
      <br />
      <br />
      Datum
      <br />
      <br />
      __________________
    </div>
    <span>(*) Unzutreffendes streichen.</span>
  </Page>
)

export default Widerrufsbelehrung
